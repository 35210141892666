import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { cmAccordionIconPanelAnimations } from './cm-accordion-icon-panel-animation';

@Component({
  selector: 'app-cm-accordion-icon-panel',
  templateUrl: './cm-accordion-icon-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: cmAccordionIconPanelAnimations,
})
export class CmAccordionIconPanelComponent {
  @Input() isOpen? = false;
  @Input() title?: string;
  @Input() isClinicLevel?: boolean;
  @Input() iconToggleClass? = 'icon-up-arrow';
  @Input() iconTitleClass? = 'icon-settings';
}
