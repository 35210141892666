import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { CmAccordionIconPanelComponent } from './utils/cm-accordion-icon-panel/cm-accordion-icon-panel.component';
import {
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTabPanelModule,
  DxBulletModule,
  DxTemplateModule,
  DxCheckBoxModule,
} from 'devextreme-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { SharedPopupModule } from './popup/popup.module';
import { DxTreeListModule } from 'devextreme-angular';

@NgModule({
  declarations: [LoadingSpinnerComponent, CmAccordionIconPanelComponent],
  imports: [
    CommonModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    ReactiveFormsModule,
    DxTabPanelModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    SharedPopupModule,
    DxCheckBoxModule,
    DxTreeListModule,
  ],
  exports: [
    LoadingSpinnerComponent,
    CommonModule,
    CmAccordionIconPanelComponent,
    DxScrollViewModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    ReactiveFormsModule,
    DxTabPanelModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    SharedPopupModule,
    DxCheckBoxModule,
    DxTreeListModule,
  ],
})
export class SharedModule {}
