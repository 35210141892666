<div class="cm-accordion-icon-panel" #cmAccordion [class.collapsed]="!isOpen">
  <div class="cm-accordion-icon-panel-head">
    <div class="icon">
      <i class="{{iconTitleClass}}"></i>
    </div>
    <div class="name cursor-pointer" (click)="isOpen=!isOpen">
      {{title}}
      <i *ngIf="isClinicLevel" class="icon-help-circled-alt question-mark" title="Go to Clinic"></i>
    </div>
    <div>
      <a class="cm-accordion-icon-panel-toggle-btn cursor-pointer" (click)="isOpen=!isOpen">
        <i [ngClass]="iconToggleClass"></i>
      </a>
    </div>
  </div>

  <div class="cm-accordion-icon-panel-content" *ngIf="isOpen" @enterLeaveHeight>
    <ng-content></ng-content>
  </div>
</div>
